import { useEffect, useState } from "react"
import { useLocation, Link } from "react-router-dom"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./header";
import hamburger from '../icons/menu.svg'
import Footer from "./footer";

function Contact(params) {
    let today = new Date();
    let actualDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

    const [contactData, setContactData] = useState({
        goal: "",
        demandType: "",
        eventType: "",
        numberOfGuests: 1,
        startTime: 0,
        endTime: 0,
        mealType: "",
        budget: "Budget approximatif",
        message: "",
        gender: "",
        social: "",
        lastName: "",
        firstName: "",
        phone: "",
        email: "",
        partnerOffers: false,
        boreasOffers: true,
        date: actualDate
    })

    const [originalEstimate, setOriginalEstimate] = useState({
        goal: "",
        demandType: "",
        eventType: "",
        numberOfGuests: 1,
        startTime: 0,
        endTime: 0,
        mealType: "",
        budget: "Budget approximatif",
        gender: "",
        lastName: "",
        firstName: "",
        phone: "",
        email: "",
        date: actualDate
    })

    const [originalContact, setOriginalContact] = useState({
        goal: "",
        demandType: "",
        gender: "",
        lastName: "",
        firstName: "",
        phone: "",
        email: "",
    })

    const [field2Title, setField2Title] = useState(null)
    const [field2Choices, setField2Choices] = useState(null)
    const [field3Choices, setField3Choices] = useState(null)
    const [timeFields, setTimeFields] = useState(null)
    const [displayPopup, setDislpayPopup] = useState(false)

    const isMobile = (params.ratio.width < 600 || params.ratio.width < params.ratio.height) ? true : false
    const [scrollPos, setScrollPos] = useState(0)
    const [displayHeader, setDisplayHeader] = useState(true)
    const [truncatedFlyer, setTruncatedFlyer] = useState(false)
    const [flyerSrc, setFlyerSrc] = useState(null)
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false)
    const [burgerOrClose, setBurgerOrClose] = useState(hamburger)
    const [formOK, setFormOK] = useState(false)
    const [sendButton, setSendButton] = useState("ENVOYER")

    console.log("************ FORM OK : ****************")
    console.log(formOK)

    console.log("params.ratio.height")
    console.log(params.ratio.height)
    console.log("params.ratio.width")
    console.log(params.ratio.width)

    console.log("contactData.date",contactData.date)
    console.log("actualDate", actualDate)

    function handleScroll() {
        if (window.scrollY > scrollPos) {
            if (window.scrollY > 50) {
                setDisplayHeader(false)
                setDisplayMobileMenu(false)
            }
            setScrollPos(window.scrollY)
        } else {
            setDisplayHeader(true)
            setScrollPos(window.scrollY)
        }
        let bg = document.getElementById("main-bg")
        if (window.scrollY < 1000 && window.scrollY > 200) {
            if (bg) {
                bg.style.filter = `brightness(120%) blur(${Math.floor((window.scrollY - 200) / 100)}px)`
            }
        }
    }

    function compareFields(toCompare, redFields) {
        if (!redFields) {
            let check = true
            Object.entries(toCompare).forEach(([k, v]) => {
                if (toCompare[k] === contactData[k] || contactData[k] === "") {
                    check = false
                }
            });
            return check
        } else {
            let lst = []
            Object.entries(toCompare).forEach(([k, v]) => {
                if (toCompare[k] === contactData[k] || contactData[k] === "") {
                    lst.push(k)
                }
            });
            return lst
        }
    }

    useEffect(() => {
        if (formOK) {
            setSendButton("ENVOYER")
        }
    }, [formOK])

    useEffect(() => {
        if (contactData.goal === "") {
            setFormOK(false)
        } else if (contactData.goal === "Demande de devis") {
            setFormOK(compareFields(originalEstimate, false))
        } else if (contactData.goal === "Contacter Le Boreas") {
            setFormOK(compareFields(originalContact, false))
        }
        if (contactData.goal === "Demande de devis" && contactData.date <= actualDate){
            setFormOK(false)
        }
    }, [contactData])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [scrollPos])

    function generateTimes(params) {
        let arr = []
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 2; j++) {
                if (j == 0) {
                    arr.push(`${i}h00`)
                } else {
                    arr.push(`${i}h30`)
                }
            }
        }
        setTimeFields([...arr])
    }

    function sendForm() {
        if (formOK) {
            fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/handleform`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    items:
                    {
                        goal: contactData.goal,
                        demandType: contactData.demandType,
                        eventType: contactData.eventType,
                        date: contactData.date.toLocaleDateString(params.language),
                        numberOfGuests: contactData.numberOfGuests,
                        startTime: contactData.startTime,
                        endTime: contactData.endTime,
                        mealType: contactData.mealType,
                        budget: contactData.budget,
                        message: contactData.message,
                        gender: contactData.gender,
                        social: contactData.social,
                        lastName: contactData.lastName,
                        firstName: contactData.firstName,
                        phone: contactData.phone,
                        email: contactData.email,
                        partnerOffers: contactData.partnerOffers,
                        boreasOffers: contactData.boreasOffers,
                        year: contactData.date.getUTCFullYear(),
                        month: contactData.date.getUTCMonth() + 1,
                        day: contactData.date.getDate(),
                        answered: false
                    }
                })
            }).then(res => {
                return res.json();
            })
                .then(data => {
                    if (data.response === "mail sent") {
                        setDislpayPopup(true)
                    }
                })
        } else {
            let list = []
            if (contactData.goal === "") {
                let e = document.getElementById("goal")
                if (e) {
                    e.style.backgroundColor = "rgb(255, 125, 125)"
                }
            } else if (contactData.goal === "Demande de devis") {
                list = compareFields(originalEstimate, true)
            } else if (contactData.goal === "Contacter Le Boreas") {
                list = compareFields(originalContact, true)
            }
            list.forEach(e => {
                let elem = document.getElementById(e)
                if (elem) {
                    elem.style.backgroundColor = "rgb(255, 125, 125)"
                }
            })
            if (contactData.goal === "Demande de devis" && contactData.date <= actualDate){
                setSendButton("Veuillez changer la date")
            } else {
                setSendButton("Veuillez remplir les champs en rouge")
            }            
        }
    }

    useEffect(() => {
        generateTimes()
    }, [])

    useEffect(() => {
        if (contactData.goal === "Demande de devis") {
            setField2Title("Vous êtes")
            setField2Choices(
                [
                    "Particulier",
                    "Professionnel",
                    "Association",
                    "Collectivité"
                ]
            )
        } else if (contactData.goal === "Contacter Le Boreas") {
            setField2Title("Type de demande")
            setField2Choices(
                [
                    "Remerciements",
                    "Suggestions",
                    "Partenariats",
                    "Autre question"
                ]
            )
        } else {
            setField2Choices(null)
            setField2Title(null)
        }
    }, [contactData.goal])

    useEffect(() => {
        if (contactData.demandType === "Particulier") {
            setField3Choices(
                [
                    "Réception de mariage",
                    "Vin d'honneur",
                    "Anniversaire",
                    "Baptême",
                    "Bar Mitzvah",
                    "Fiançailles",
                    "Réunion de famille",
                    "Soirée Arcade/Casino",
                    "Événement sur mesure"
                ]
            )
        } else if (contactData.demandType === "Professionnel") {
            setField3Choices(
                [
                    "Déjeuner d'affaire",
                    "Dîner d'affaire",
                    "Événementiel",
                    "Lancement de produit",
                    "Présentation des vœux",
                    "Réception privée",
                    "Réunion de travail",
                    "Séminaire",
                    "Soirée d'entreprise",
                    "Défilé de mode",
                    "Dégustation",
                    "Spectacle",
                    "Événement sur mesure"
                ]
            )
        } else if (contactData.demandType === "Association" || contactData.demandType === "Collectivité") {
            setField3Choices(
                [
                    "Spectacle",
                    "Vœux du maire/président",
                    "Séminaire",
                    "Séminaire Politique",
                    "Remise de diplôme",
                    "Journée d'intégration",
                    "Journée Sénior",
                    "Soirée de gala",
                    "Événement sur mesure",
                ]
            )
        }
    }, [contactData.demandType])

    return (
        <div id="contact-main-wrapper">
            <div id="contact-bg">

            </div>
            <Header
                isMobile={isMobile}
                displayHeader={displayHeader}
                setDisplayMobileMenu={setDisplayMobileMenu}
                displayMobileMenu={displayMobileMenu}
                burgerOrClose={burgerOrClose}
            />
            {displayPopup && <div id="form-sent-popup">
                <div id="sent-popup">
                    <p
                    style={{
                        textAlign: "center"
                    }}
                        className="product-text form-text"
                    >Le formulaire a bien été envoyé !</p>
                    <p
                    style={{
                        textAlign: "center"
                    }}
                        className="product-text form-text"
                    >Nous vous répondrons dans les plus bref délais</p>
                    <Link to="/"
                    >
                        <button
                            style={{
                                cursor: 'pointer'
                            }}

                            id="back-button">Retour à l'accueil</button>
                    </Link>
                </div>
            </div>}
            {/* <div className="booking-title">
                
            </div> */}
            <h1
                style={{

                }}
                className="boreas-subtitle"

            >Nous contacter</h1>
            <div className="contact-form-wrapper">
                <p className="product-text form-text"
                    id="form-first-line1"
                >Objet de votre demande</p>
                <div
                    className="label-and-input contact-block"
                >
                    <p
                        className="product-text form-text"
                    >Votre demande <span className="form-span">*</span></p>
                    <select
                        id="goal"
                        className="form-input"
                        onChange={(e) => {
                            setContactData(cd => ({
                                ...cd,
                                goal: e.target.value
                            }))
                            e.target.style.backgroundColor = "white"
                        }}
                    >
                        <option key="Veuillez sélectionner field1" value="- Sélectionner -">- Sélectionner -</option>
                        <option value="Demande de devis">Demande de devis</option>
                        <option value="Contacter Le Boreas">Contacter Le Boreas</option>
                    </select>
                </div>
                {(field2Choices && field2Title) && <div
                    className="label-and-input contact-block"
                >
                    <p
                        className="product-text form-text"
                    >{field2Title} <span className="form-span">*</span></p>
                    <select
                        id="demandType"
                        className="form-input"
                        onChange={(e) => {
                            setContactData(cd => ({
                                ...cd,
                                demandType: e.target.value
                            }))
                            e.target.style.backgroundColor = "white"
                        }}
                    >
                        <option key="Veuillez sélectionner field2" value={"- Sélectionner -"}>- Sélectionner -</option>
                        {field2Choices.map((choice) => {
                            return (
                                <option key={choice} value={choice}>{choice}</option>
                            )
                        })}
                    </select>
                </div>}
                {(field3Choices && contactData.goal === "Demande de devis") &&
                    <div
                        className="label-and-input contact-block"
                    >
                        <p
                            className="product-text form-text"
                        >Type d'évènement <span className="form-span">*</span></p>
                        <select
                            id="eventType"
                            className="form-input"
                            onChange={(e) => {
                                setContactData(cd => ({
                                    ...cd,
                                    eventType: e.target.value
                                }))
                                e.target.style.backgroundColor = "white"
                            }}
                        >
                            <option key="Veuillez sélectionner field3" value="Veuillez sélectionner">"Veuillez sélectionner"</option>
                            {
                                field3Choices.map((choice) => {
                                    return (
                                        <option key={choice} value={choice}>{choice}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                }
                {(field3Choices && contactData.goal === "Demande de devis") &&
                    <div
                        className="label-and-input contact-block"
                    >
                        <p
                            className="product-text form-text"
                        >Date de l'événement <span className="form-span">*</span></p>
                        <DatePicker
                            className="form-input simple-input"
                            onChange={(e) => setContactData(cd => ({
                                ...cd,
                                date: e
                            }))}
                            selected={contactData.date}
                            dateFormat="dd/MM/yyyy"
                            id="date-field"
                        ></DatePicker>
                    </div>
                }
                {(field3Choices && contactData.goal === "Demande de devis") &&
                    <div
                        className="label-and-input contact-block"
                    >
                        <p
                            className="product-text form-text"
                        >Nombre de participants <span className="form-span">*</span></p>
                        <input
                            id="numberOfGuests"
                            className="form-input simple-input"
                            value={contactData.numberOfGuests}
                            onChange={(e) => {
                                setContactData(cd => ({
                                    ...cd,
                                    numberOfGuests: e.target.value
                                }))
                                e.target.style.backgroundColor = "white"
                            }}
                            type="number"
                            min="1"
                            max="200"
                        ></input>
                    </div>
                }
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "49%",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    {(field3Choices && contactData.goal === "Demande de devis") &&
                        <div
                            className="label-and-input contact-block"
                            style={{
                                width: "49%",
                            }}
                        >
                            <p
                                className="product-text form-text"
                                style={{
                                    margin: 0
                                }}
                            >Horaires <span className="form-span">*</span></p>
                            <select
                                id="startTime"
                                style={{
                                    margin: 0
                                }}
                                className="form-input"
                                onChange={(e) => {
                                    setContactData(cd => ({
                                        ...cd,
                                        startTime: e.target.value
                                    }))
                                    e.target.style.backgroundColor = "white"
                                }}
                            >
                                <option key={"from"} value={"de"}>De:</option>
                                {
                                    timeFields.map((tf) => {
                                        return (
                                            <option key={tf} value={tf}>{tf}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    {(field3Choices && contactData.goal === "Demande de devis") &&
                        <div
                            className="label-and-input contact-block"
                            style={{
                                width: "47.5%"
                            }}
                        >
                            <p
                                className="product-text form-text"
                                style={{
                                    margin: 0
                                }}
                            >&nbsp;</p>
                            <select
                                id="endTime"
                                style={{
                                    margin: 0
                                }}
                                className="form-input"
                                onChange={(e) => {
                                    setContactData(cd => ({
                                        ...cd,
                                        endTime: e.target.value
                                    }))
                                    e.target.style.backgroundColor = "white"
                                }}
                            >
                                <option
                                    key={"to"} value={"à"}>À:</option>
                                {
                                    timeFields.map((tf) => {
                                        return (
                                            <option key={tf} value={tf}>{tf}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                </div>
                {(field3Choices && contactData.goal === "Demande de devis") &&
                    <div
                        className="label-and-input contact-block"
                    >
                        <p
                            className="product-text form-text"
                        >Type de repas <span className="form-span">*</span></p>
                        <select
                            id="mealType"
                            className="form-input"
                            onChange={(e) => {
                                setContactData(cd => ({
                                    ...cd,
                                    mealType: e.target.value
                                }))
                                e.target.style.backgroundColor = "white"
                            }}
                        >
                            <option key="Veuillez sélectionner field8" value="Veuillez sélectionner">"Veuillez sélectionner"</option>
                            <option value="Cocktail">Cocktail</option>
                            <option value="Repas assis bistronomique">Repas assis bistronomique</option>
                            <option value="Repas assis gastronomique">Repas assis gastronomique</option>
                            <option value="Buffet">Buffet</option>
                            <option value="Collation ">Collation</option>
                            <option value="Autre">Autre</option>
                        </select>
                    </div>
                }
                {(field3Choices && contactData.goal === "Demande de devis") &&
                    <div
                        className="label-and-input contact-block"
                    >
                        <p
                            className="product-text form-text"
                        >Budget <span className="form-span">*</span></p>
                        <input
                            id="budget"
                            className="form-input simple-input"
                            onChange={(e) => {
                                setContactData(cd => ({
                                    ...cd,
                                    budget: e.target.value
                                }))
                                e.target.style.backgroundColor = "white"
                            }}
                            value={contactData.budget}
                            type="text"
                        >
                        </input>
                    </div>
                }
                <div
                    className="label-and-input contact-block"
                    id="message-area"
                >
                    <p
                        style={{
                            marginLeft: 0
                        }}
                        className="product-text form-text"
                    >Votre message:</p>
                    <textarea
                        className="form-input"
                        id="message"
                        onChange={(e) => setContactData(cd => ({
                            ...cd,
                            message: e.target.value
                        }))}
                        value={contactData.message}
                        style={{
                            resize: "none"
                        }}
                    >
                    </textarea>
                </div>
            </div>
            <div className="contact-form-wrapper">
                <p className="product-text form-text"
                    id="form-first-line2"
                >Vos informations</p>
                <div
                    className="label-and-input contact-block"
                >
                    <p
                        className="product-text form-text"
                    >Civilité <span className="form-span">*</span></p>
                    <select
                        id="gender"
                        className="form-input"
                        onChange={(e) => {
                            setContactData(cd => ({
                                ...cd,
                                gender: e.target.value
                            }))
                            e.target.style.backgroundColor = "white"
                        }}
                    >
                        <option key="Veuillez sélectionner field1" value="Veuillez sélectionner">"Veuillez sélectionner"</option>
                        <option value="Monsieur">Monsieur</option>
                        <option value="Madame">Madame</option>
                    </select>
                </div>
                <div
                    className="label-and-input contact-block"
                >
                    <p
                        className="product-text form-text"
                    >Dénomination sociale</p>
                    <input
                        className="form-input simple-input"
                        onChange={(e) => setContactData(cd => ({
                            ...cd,
                            social: e.target.value
                        }))}
                        value={contactData.social}
                    >
                    </input>
                </div>
                <div
                    className="label-and-input contact-block"
                >
                    <p
                        className="product-text form-text"
                    >Nom <span className="form-span">*</span></p>
                    <input
                        id="lastName"
                        className="form-input simple-input"
                        onChange={(e) => {
                            setContactData(cd => ({
                                ...cd,
                                lastName: e.target.value
                            }))
                            e.target.style.backgroundColor = "white"
                        }}
                        value={contactData.lastName}
                    >
                    </input>
                </div>
                <div
                    className="label-and-input contact-block"
                >
                    <p
                        className="product-text form-text"
                    >Prénom <span className="form-span">*</span></p>
                    <input
                        id="firstName"
                        className="form-input simple-input"
                        onChange={(e) => {
                            setContactData(cd => ({
                                ...cd,
                                firstName: e.target.value
                            }))
                            e.target.style.backgroundColor = "white"
                        }}
                        value={contactData.firstName}
                    >
                    </input>
                </div>
                <div
                    className="label-and-input contact-block"
                >
                    <p
                        className="product-text form-text"
                    >Téléphone <span className="form-span">*</span></p>
                    <input
                        id="phone"
                        className="form-input simple-input"
                        onChange={(e) => {
                            setContactData(cd => ({
                                ...cd,
                                phone: e.target.value
                            }))
                            e.target.style.backgroundColor = "white"
                        }}
                        value={contactData.phone}
                    >
                    </input>
                </div>
                <div
                    className="label-and-input contact-block"
                >
                    <p
                        className="product-text form-text"
                    >Email <span className="form-span">*</span></p>
                    <input
                        id="email"
                        className="form-input simple-input"
                        onChange={(e) => {
                            setContactData(cd => ({
                                ...cd,
                                email: e.target.value
                            }))
                            e.target.style.backgroundColor = "white"
                        }}
                        value={contactData.email}
                    >
                    </input>
                </div>
                {/* <div
                    className="checkbox-div"
                    id="first-checkbox"
                >
                    <input
                        style={{
                            cursor: "pointer"
                        }}
                        className="form-checkbox"
                        onChange={(e) => setContactData(cd => ({
                            ...cd,
                            partnerOffers: !contactData.partnerOffers
                        }))}
                        type="checkbox"
                        id="partner-offers"
                    >
                    </input>
                    <label
                        className="checkbox-text"
                        for="partner-offers">J'accepte de recevoir les offres commerciales des partenaires du Boreas</label>
                </div>
                <div
                    className="checkbox-div"
                >
                    <input
                        style={{
                            cursor: "pointer"
                        }}
                        className="form-checkbox"
                        onChange={(e) => setContactData(cd => ({
                            ...cd,
                            boreasOffers: !contactData.boreasOffers
                        }))}
                        type="checkbox"
                        id="boreas-offers"
                    >
                    </input>
                    <label
                        className="checkbox-text"
                        for="boreas-offers">Je ne souhaite pas recevoir les offres commerciales du Boreas</label>
                </div> */}
                <button
                    style={{
                        cursor: "pointer"
                    }}
                    id="contact-send"
                    className="contact-send-btn"
                    onClick={(e) => {
                        setTimeout(() => {
                            e.target.classList.remove("send-form")
                            sendForm()
                        }, 250);
                        e.target.classList.add("send-form")
                    }}
                >
                    {sendButton}
                </button>
            </div>
            <Footer />
        </div>
    )
}

export default Contact