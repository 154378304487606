import { Link } from 'react-router-dom'

function Footer(params) {

    return (
        <div id="footer">
            <Link to="/contact"
                onClick={() => {
                    setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }, 50)
                }
                }
            >
                <p className="footer-text">CONTACT</p>
            </Link>
        </div>
    )
}

export default Footer