import Header from "./header"
import hamburger from '../icons/menu.svg'
import close from "../icons/close.svg"
import { useState, useEffect } from "react"
import { useLocation, useHistory, Link } from "react-router-dom"
import MyBookingDetails from "./mybookingdetails"
import Footer from "./footer"
import FlyerDisplayer from "./flyerdisplayer"
import { CSSTransition } from "react-transition-group"

function Mybooking(params) {

    let history = useHistory()

    const isMobile = (params.ratio.width < 600 || params.ratio.width < params.ratio.height) ? true : false
    const [scrollPos, setScrollPos] = useState(0)
    const [displayHeader, setDisplayHeader] = useState(true)
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false)
    const [burgerOrClose, setBurgerOrClose] = useState(hamburger)

    const [email, setEmail] = useState("")
    const [bookingCode, SetBookingCode] = useState("")
    const [action, setAction] = useState("enter-info")
    const [badSearchPopup, setBadSearchPopup] = useState(false)
    const [bookingData, SetBookingData] = useState(null)
    const [day, setDay] = useState("")
    const [flyerSrc, setFlyerSrc] = useState(null)
    const [truncatedFlyer, setTruncatedFlyer] = useState(false)
    const [flyerQuery, setFlyerQuery] = useState(false)

    console.log("email in my booking")
    console.log(email)

    console.log("booking data in mybooking :")
    console.log(bookingData)

    function handleScroll() {
        if (window.scrollY > scrollPos) {
            if (window.scrollY > 50) {
                setDisplayHeader(false)
                setDisplayMobileMenu(false)
            }
            setScrollPos(window.scrollY)
        } else {
            setDisplayHeader(true)
            setScrollPos(window.scrollY)
        }
        let bg = document.getElementById("main-bg")
        if (window.scrollY < 1000 && window.scrollY > 200) {
            if (bg) {
                bg.style.filter = `brightness(120%) blur(${Math.floor((window.scrollY - 200) / 100)}px)`
            }
        }
    }

    function handleValidateCheck() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getmybooking`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email.toLowerCase(),
                bookingCode: bookingCode.toUpperCase()
            })
        }).then(res => {
            return res.json();
        })
            .then(data => {
                if (data && data.response !== "not found") {
                    SetBookingData(data)
                    setDay(new Date(data.clientBooking.year, data.clientBooking.month - 1, data.clientBooking.day))
                    setAction("consulting")
                    console.log("date returned from getmybooking")
                    console.log(data)
                } else {
                    console.log("date returned from getmybooking")
                    console.log(data)
                    setBadSearchPopup(true)
                }
            })
    }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        let email = query.get("email")
        if (email) {
            setEmail(email)
        } else {
            setEmail("Saisir l'email de réservation")
        }
        let bookingCode = query.get("code")
        if (bookingCode) {
            SetBookingCode(bookingCode)
        } else {
            SetBookingCode("Saisir les 12 caractères EX: A1B2C3D4E5F6")
        }
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [scrollPos])

    useEffect(() => {
        if (displayMobileMenu) {
            setBurgerOrClose(close)
        } else {
            setBurgerOrClose(hamburger)
        }
    }, [displayMobileMenu])

    function handleFrameClick(flyer) {
        setTruncatedFlyer(true)
        setFlyerSrc(flyer)
        setDisplayHeader(false)
        history.push({
            search: `?displayflyer=true`
        })
    }

    function HandleFlyerClick(params) {
        setTruncatedFlyer(false)
        setDisplayHeader(true)
    }

    let queryFlyer = query.get("displayflyer")

    if (!queryFlyer) {
        if (flyerQuery === true) {
            setFlyerQuery(false)
        }
    } else if (queryFlyer === "true") {
        if (flyerQuery === false) {
            setFlyerQuery(true)
        }
    }

    useEffect(() => {
        if (flyerQuery === false) {
            HandleFlyerClick()
        }
    }, [flyerQuery])

    return (
        <div id="main-booking-wrapper">
            <div id="main-bg">

            </div>
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={truncatedFlyer}
                timeout={500}
                classNames="flyer-transition"
            >
                <FlyerDisplayer
                    style={{
                        zIndex: 100
                    }}
                    src={flyerSrc}
                    HandleFlyerClick={HandleFlyerClick}
                    setTruncatedFlyer={setTruncatedFlyer} />
            </CSSTransition>
            <Header
                isMobile={isMobile}
                displayHeader={displayHeader}
                setDisplayMobileMenu={setDisplayMobileMenu}
                displayMobileMenu={displayMobileMenu}
                burgerOrClose={burgerOrClose}
            />
            {
                action === "enter-info" &&
                <div id="enter-info-mybooking">
                    {
                        badSearchPopup &&
                        <div id="bad-search-popup">
                            <p>Aucune réservation ne correspond aux éléments saisies,
                                vérifiez vos informations. Si elle sont correctes,&nbsp;
                                <Link
                                    style={{
                                        textDecoration: "underline",
                                        color: "red"
                                    }}
                                    to="/contact">contactez le service client</Link>
                            </p>
                        </div>
                    }
                    <h1
                        className="mybooking-title"
                    >Accéder à votre réservation</h1>
                    <p className="mybooking-label">email de réservation</p>
                    <input
                        className="mybooking-input"
                        value={email}
                        onClick={() => {
                            if (email === "Saisir l'email de réservation") {
                                setEmail("")
                            }
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="mybooking-label"
                        id="my-booking-second-label"
                    >numéro de réservation</p>
                    <input
                        className="mybooking-input"
                        value={bookingCode}
                        onClick={() => {
                            if (bookingCode === "Saisir les 12 caractères EX: A1B2C3D4E5F6") {
                                SetBookingCode("")
                            }
                        }}
                        onChange={(e) => SetBookingCode(e.target.value)}
                    />
                    <p id="where-to-find-booking-number">
                        Votre numéro de réservation est indiqué dans votre
                        email de confirmation
                    </p>
                    <p id="mybooking-forgotten-link"
                        onClick={() => setAction("forgotten")}
                    >
                        Numéro de réservation oublié ?
                    </p>
                    <button
                        className="mybooking-input"
                        id="mybooking-validate-check"
                        onClick={() => handleValidateCheck()}
                    >VALIDER</button>
                </div>
            }
            {
                action === "forgotten" &&
                <div>
                    building
                </div>
            }
            {
                action === "consulting" &&
                <div id="mybooking-details-wrapper">
                    <h1
                        className="mybooking-title"
                        style={{
                            textAlign: "center"
                        }}
                    >Réservation n° {bookingCode}</h1>
                    <div id="mybooking-details-and-options">
                    <MyBookingDetails
                        {...bookingData.event}
                        eventName={bookingData.event.title}
                        handleFrameClick={handleFrameClick}
                        // price={bookingData.clientBooking.vipOption ? bookingData.event.vipPrice * bookingData.clientBooking.numberOfGuest + bookingData.event.vipKidPrice * bookingData.clientBooking.numberOfKid :
                        //     bookingData.event.price * bookingData.clientBooking.numberOfGuest + bookingData.event.kidPrice * bookingData.clientBooking.numberOfKid + (bookingData.clientBooking.option1Chosen != "Non" ? bookingData.event.option1Price * bookingData.clientBooking.numberOfGuest : 0)}
                        price={bookingData.clientBooking.price}
                        date={bookingData.clientBooking.date}
                        day={day.toLocaleDateString('fr-FR', { weekday: 'long' })}
                        requestMode={false}
                        vipOption={bookingData.clientBooking.vipOption}
                        numberOfGuests={bookingData.clientBooking.numberOfGuest}
                        numberOfKid={bookingData.clientBooking.numberOfKid}
                        kidPrice={bookingData.clientBooking.vipOption ? bookingData.event.vipKidPrice : bookingData.event.kidPrice}
                        adultPrice={bookingData.clientBooking.vipOption ? bookingData.event.vipPrice : bookingData.event.price}
                        // optionPrice={bookingData.clientBooking.option1Chosen != "Non" ? bookingData.event.option1Price * bookingData.clientBooking.numberOfGuest : 0}
                        optionPrice={bookingData.clientBooking.option1Amount * bookingData.event.option1Price + bookingData.clientBooking.option2Amount * bookingData.event.option2Price}
                    />
                        <div id="mybooking-options">
                            <p className="mybooking-options-text">
                                Ajouter des passagers
                            </p>
                            <div
                                style={{
                                    width: "90%",
                                    height: "2px",
                                    backgroundColor: "rgb(242, 135, 5)",
                                }}
                            />
                            <p className="mybooking-options-text">
                                Annuler une réservation
                            </p>
                            <div
                                style={{
                                    width: "90%",
                                    height: "2px",
                                    backgroundColor: "rgb(242, 135, 5)",
                                }}
                            />
                            <p className="mybooking-options-text">
                                Accompagnement PMR
                            </p>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}

export default Mybooking